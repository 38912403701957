import { useSelector, useDispatch } from 'react-redux';
import { createSelector, ParametricSelector } from 'reselect';

import { PostsState } from '../store/types';
import { loadPosts } from '../store/actions';
import { RootState } from '../store/reducers';

// const selectUserContest: ParametricSelector<RootState, string, { posts: PostsState, Post: PostState }> =
const selectUserContest: ParametricSelector<RootState, undefined, { posts: PostsState }> =
    // createSelector<RootState, string, PostsState, PostState, { posts: PostsState, Post: PostState }>(
    createSelector<RootState, PostsState, { posts: PostsState }>(
        state => state.post,
        // (state, postKey) => state.Post[postKey],
        // (posts, Post) => ({ posts, Post })
        (posts) => ({ posts })
    );

// export const usePostState = (postKey: string) => {
export const usePostState = () => {
    // return useSelector((state: RootState) => selectUserContest(state, postKey));
    return useSelector((state: RootState) => selectUserContest(state, undefined));
}

export const usePostDispatch = () => {
    const dispatch = useDispatch();
    return {
        loadPosts: () => () => dispatch(loadPosts())
    }
}
