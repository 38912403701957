import React from 'react';
import { BugFilled } from '@ant-design/icons'

import './NotFound.scss';

export default () => {
    return (
        <div className="not-found">
            <BugFilled/>
            <div>Not Found...</div>
        </div>
    )
}
