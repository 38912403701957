import React from 'react';

import './Home.scss';
import { Typography } from 'antd';

export default () => {
  const { Title } = Typography;
  return (
    <div style={{ padding: '20%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <img src="/images/me.jpg" width="200em" alt="home-draw" style={{ borderRadius: '50%' }}/>
      <br/>
      <Title level={1}>
        Blog personal de Oscar Gauss
      </Title>
    </div>
  );
}
