import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';

import { post } from './post';
// import { USER_ACTIONS } from '../types';

export const history = createBrowserHistory();

const appReducer = combineReducers({
    router: connectRouter(history),
    post
});

export default (state: any, action: any) => {
    // if (action.type === USER_ACTIONS.LOGOUT_USER) { // to remove the redux status
    //     state = undefined;
    // }
    return appReducer(state, action)
};

export type RootState = ReturnType<typeof appReducer>
