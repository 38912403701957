import React from 'react';
import { Card, Timeline, Typography, Row, Col } from 'antd';
import * as DateFns from 'date-fns';
import { Link } from 'react-router-dom';
import { SmallDashOutlined } from '@ant-design/icons';

import './List.scss';
import { usePostState } from '../../../hooks/post';
import { ROUTES } from '../../../config/routes';
import { MdViewer } from '../../Shared/MdViewer';

export default () => {
  const { Title } = Typography;
  const { posts } = usePostState();
  return (
    <>
      <Title style={{ position: 'fixed' }}>Publicaciones</Title>
      <Row style={{ paddingTop: '60px' }}>
        <Col flex="100px" style={{ position: 'fixed' }}>
          <img src="/images/undraw_online_view_np7n.svg" width="100%" alt="person-watching"/>
        </Col>
        <Col flex="auto">
          <Timeline mode="left">
            {Object.values(posts)
              .sort((a, b) => b.date.getTime() - a.date.getTime())
              .map(post => {
                return (
                  <Timeline.Item label={DateFns.format(post.date, 'd MMMM yyyy, HH:mm ')} key={post.key}>
                    <Card
                      title={post.title}
                      extra={<Link to={ROUTES.POSTS.PAGE(post.key)}>Ver</Link>}
                      style={{ width: 300 }}>
                      <div style={{ maxHeight: '120px', overflow: 'hidden' }}>
                        <MdViewer source={post.content}/>
                      </div>
                      <SmallDashOutlined style={{fontSize: '42px'}}/>
                    </Card>
                  </Timeline.Item>
                );
              })}
          </Timeline>
        </Col>
      </Row>
    </>
  );
}
