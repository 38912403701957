import React, { useEffect } from 'react';
import { Route, Switch, useLocation, Link } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { TwitterOutlined, FacebookOutlined, MailOutlined } from '@ant-design/icons';

import Home from './components/Home';
import PostList from './components/Post/List';
import Post from './components/Post/Post';
import Resume from './components/Resume';
import About from './components/About';
import NotFound from './components/NotFound';
import { history } from './store/reducers';
import { ROUTES } from './config/routes';

import './App.scss';
import { usePostDispatch } from './hooks/post';

export default () => {
  const { loadPosts } = usePostDispatch();
  useEffect(() => {
    loadPosts()();
  }, [loadPosts]);
  const { Header, Content, Footer } = Layout;
  const params = useLocation().pathname.split('/');
  return (
    <Layout>
      <Header>
        <Link to={'/'}>
          <div className="logo">
            Oscar Gauss
          </div>
        </Link>
        <div style={{ flex: '1' }}/>
        <Menu theme="dark" mode="horizontal" defaultSelectedKeys={[params[1]]}>
          <Menu.Item
            key={ROUTES.PARAMS.POSTS}
            onClick={() => history.push(ROUTES.POSTS.PAGE())}
          >
            Blog
          </Menu.Item>
          <Menu.Item
            key={ROUTES.PARAMS.RESUME}
            onClick={() => history.push(ROUTES.RESUME.PAGE)}
          >
            C.V.
          </Menu.Item>
          <Menu.Item
            key={ROUTES.PARAMS.ABOUT}
            onClick={() => history.push(ROUTES.ABOUT.PAGE)}
          >
            Sobre mi
          </Menu.Item>
        </Menu>
      </Header>
      <Content>
        <div className="site-layout-content">
          <Switch>
            <Route exact path={'/'} component={Home}/>
            <Route exact path={ROUTES.POSTS.PAGE()} component={PostList}/>
            <Route exact path={ROUTES.POSTS.PAGE(':key')} component={Post}/>
            <Route exact path={ROUTES.RESUME.PAGE} component={Resume}/>
            <Route exact path={ROUTES.ABOUT.PAGE} component={About}/>
            <Route exact path={'*'} component={NotFound}/>
          </Switch>
        </div>
      </Content>
      <Footer style={{ textAlign: 'center' }}>
        <Link to={{ pathname: 'https://twitter.com/Oscar_Gauss' }}><TwitterOutlined/></Link>
        <Link to={{ pathname: 'https://www.facebook.com/oscargauss' }}><FacebookOutlined/></Link>
        <a href={'mailto:oscargausscarvajal@gmail.com'}><MailOutlined/></a>
      </Footer>
    </Layout>
  );
}
