import { PostState, POST_ACTIONS, PostActionTypes, PostsState } from '../types';

export const post = (state: PostsState = {}, action: PostActionTypes) => {
    const emptyState: { [key: string]: PostState } = {};
    switch (action.type) {
        case POST_ACTIONS.LOAD_POSTS:
            action.posts.forEach(post => emptyState[post.key] = post);
            return emptyState;
        case POST_ACTIONS.REPLACE_POSTS:
            return { ...action.posts };
        default:
            return state;
    }
};
