import React from 'react';
import { Typography } from 'antd';

export default () => {
  const { Title } = Typography;
  return (
    <div style={{ padding: '20%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Title level={4}>
        Mi nombre es Oscar Gauss Carvajal Yucra, soy estudiante de la carrera de Informática de la Universidad Mayor de San Andrés ...
      </Title>
    </div>
  );
}
