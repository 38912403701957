export const ROUTES = {
    ROOT: '/',
    PARAMS: {
        POSTS: 'posts',
        RESUME: 'resume',
        ABOUT: 'about'
    },
    POSTS: {
        PAGE(key?: string) {
            return '/' + ROUTES.PARAMS.POSTS + (key ? ('/' + key) : '');
        }
    },
    RESUME: {
        PAGE: '/resume'
    },
    ABOUT: {
        PAGE: '/about'
    }
};
