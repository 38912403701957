// https://medium.com/@MatDrinksTea/rendering-markdown-and-latex-in-react-dec355e74119
// the file is .js because not found types for react-mathjax
import React            from 'react';
import ReactMarkdown    from 'react-markdown';
import MathJax          from 'react-mathjax';
import RemarkMathPlugin from 'remark-math';

import './MdViewer.scss';

export const MdViewer = (props) => {
  const newProps = {
    ...props,
    plugins: [
      RemarkMathPlugin
    ],
    renderers: {
      ...props.renderers,
      math: (props) =>
        <MathJax.Node formula={props.value}/>,
      inlineMath: (props) =>
        <MathJax.Node inline formula={props.value}/>
    }
  };

  return (
    <div className="mathMarkDownViewer">
      <MathJax.Provider input="tex">
        <ReactMarkdown {...newProps} />
      </MathJax.Provider>
    </div>
  );
};

