export interface PostState {
    author: string,
    key: string,
    title: string,
    date: Date,
    content: string
}

export type PostsState = { [key: string]: PostState };

export enum POST_ACTIONS {
    LOAD_POSTS = 'LOAD_POSTS',
    REPLACE_POSTS = 'REPLACE_POSTS'
}

interface LoadPostsAction {
    type: POST_ACTIONS.LOAD_POSTS,
    posts: Array<PostState>,
}

interface ReplacePostsAction {
    type: POST_ACTIONS.REPLACE_POSTS,
    posts: PostsState,
}

export type PostActionTypes = LoadPostsAction | ReplacePostsAction;
