import { Middleware } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';

import rootReducer, { history } from './reducers'
import { configureStore } from '@reduxjs/toolkit';

const logger: Middleware = (store) => (next) => (action: any) => {
  // console.info('dispatching', action);
  const result = next(action);
  // console.info('next state', store.getState());
  return result;
};

export default configureStore({
  reducer: rootReducer,
  middleware: [routerMiddleware(history), logger, thunk] as const
})
