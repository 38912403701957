import React from 'react';
import { useParams } from 'react-router';
import { Typography } from 'antd';
import * as DateFns from 'date-fns';

import { MdViewer } from '../../Shared/MdViewer';
import { usePostState } from '../../../hooks/post';

function App() {
    const { posts } = usePostState();
    const params: { key?: string } = useParams();
    const post = posts[params.key || ''] || { title: '...', content: '', date: new Date() };
    const { Title } = Typography;
    return (
        <div>
            <Title>{post.title}</Title>
            <Title level={4}>{DateFns.format(post.date, 'd MMMM yyyy, HH:mm ')}</Title>
            <MdViewer source={post.content}/>
        </div>
    );
}

export default App;
