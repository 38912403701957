import { POST_ACTIONS, PostsState } from '../types';

export const replacePosts = (posts: PostsState) => {
    return {
        type: POST_ACTIONS.REPLACE_POSTS,
        posts
    }
}

export const getPlainText = async (url: string) => {
    return await fetch(url)
        .then(data => data.text())
        .catch((error) => {
            console.error('ERROR:', error);
            return '';
        });
};

export const loadPosts = () => {
    return async (dispatch: Function) => {
        const data = require('../../config/posts.json');
        const posts: PostsState = {};
        for (const post of data.list) {
            posts[post.key] = {
                author: post.author,
                key: post.key,
                title: post.title,
                date: new Date(post.date),
                content: await getPlainText(post.url)
            }
        }
        dispatch(replacePosts(posts));
    }
};
